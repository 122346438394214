export type InmailMenuListType = {
    id: string | number
    title? : string
    name: string
    unReadNum: number
    icon?: string
    isLoading?: boolean
    codeName?: string
}

export type InmailCollapseType = {
    id: string | number
    name: string
    unReadNum: number
    codeName?: string
    icon?: string
}

export enum IconType {
    setting='msg_set_def',
    sethover='msg_set_def',
    read='icon_confirm',
    readhover='icon_confirm',
    more='icon_c2c_more',
    morehover='icon_c2c_more'
}

export enum InmailStringType {
    one='1',
    two='2',
    three='3',
    four='4',
}

export enum InmailNumType {
    one=1,
    two=2,
    three=3,
    four=4,
    seven=7
}

export enum InmailStrongEnum {
    warning = 'liquidateWarning', // 强平预警
    notice = 'liquidateNotice', // 强平通知
    settlement = 'settlementNotice', // 交割通知
}
